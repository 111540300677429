import type { TypedAddListener, TypedStartListening } from '@reduxjs/toolkit';
import { addListener, createListenerMiddleware } from '@reduxjs/toolkit';

import store from 'store';
import type { Store } from 'store/reducers';

type AppDispatch = typeof store.store.dispatch;

export const listenerMiddleware = createListenerMiddleware();

export type AppStartListening = TypedStartListening<Store, AppDispatch>;

export const startAppListening =
  listenerMiddleware.startListening as AppStartListening;

export const addAppListener = addListener as TypedAddListener<
  Store,
  AppDispatch
>;
